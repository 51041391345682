import React from "react";
import "./styles.scss";

function ShareIcons(props) {
    return (
        <div className="ShareIcons__share">
            {props.share ? <div className="share-title akkurat-bold-font">Share to:</div> : null}
            <div className={props.small ? "share-buttons-small" : "share-buttons"}>
                {/* <!-- Facebook --> */}
                <a href={`https://www.facebook.com/v3.3/dialog/share?app_id=2492249767571476&href=${encodeURIComponent(props.url)}&display=page&redirect_uri=${encodeURIComponent(props.url)}`} target="_new">
                    <img src={process.env.PUBLIC_URL + '/assets/landing_assets/social/social-facebook.png'} alt="Share Govest on Facebook" />
                </a>

                {/* <!-- Twitter --> */}
                <a href={`https://twitter.com/share?url=${props.url}&amp;text=${encodeURIComponent(`${props.title} | Govest`)}&amp;hashtags=govest`} target="_new">
                    <img src={process.env.PUBLIC_URL + '/assets/landing_assets/social/social-twitter.png'} alt="Twitter" />
                </a>

                {/* <!-- Whatsapp --> */}
                <a href={`https://wa.me/?text=${encodeURIComponent(`${props.title} | Govest\n${props.url}`)}`} target="_new">
                    <img src={process.env.PUBLIC_URL + '/assets/landing_assets/social/social-whatsapp.png'} alt="LinkedIn" />
                </a>

                {props.full ? (
                    <>
                        {/* <!-- LinkedIn --> */}
                        <a href={`http://www.linkedin.com/shareArticle?mini=true&amp;url=${props.url}`} target="_new">
                            <img src={process.env.PUBLIC_URL + '/assets/landing_assets/social/social-linkedin.png'} alt="LinkedIn" />
                        </a>

                        {/* <!-- Reddit --> */}
                        <a href={`http://reddit.com/submit?url=${props.url}&amp;title=${props.title} | Govest`} target="_new">
                            <img src={process.env.PUBLIC_URL + '/assets/landing_assets/social/social-reddit.png'} alt="Reddit" />
                        </a>
                    </>
                ) : null}

            </div>
        </div>
    );
}
export default ShareIcons;