import React, { useState, useEffect } from "react";
import axios from 'axios';
import VerificationStatusSection from "../../components/VerificationStatusSection";
import "./styles.scss";

function EmailVerificationPage(props) {
  const [emailState, setEmailState] = useState("loading");

  let { email, token } = props.match.params;

  const getEmailState = () => {
    setEmailState("loading");
    axios.get(`https://www.go-vest.com/api/v0/user/emailverification/${email}/${token}`)
      .then(function (response) {
        if (response.data.status == 'success')
          setEmailState('success');
        else if (response.data.status == 'verified')
          setEmailState('verified');
      })
      .catch(function (error) {
        setEmailState('fail');
      });
  }

  useEffect(() => {
    getEmailState();
  }, []);

  const emailStates = {
    loading: {
      title: 'Verifying your Email Address',
      subtitle: 'Verifying...',
      image: '/assets/landing_assets/verifyloading.png',
    },
    success: {
      title: 'Success! Your Email is Verified',
      subtitle: 'Continue your registration process in the Govest App.',
      image: '/assets/landing_assets/verifysuccess.png',
    },
    verified: {
      title: 'Your email has been verified',
      subtitle: '',
      image: '/assets/landing_assets/verifysuccess.png',
    },
    fail: {
      title: 'Something Went Wrong!',
      subtitle: 'We are unable to verify your email address. Click the chat button to contact us.',
      image: '/assets/landing_assets/verifyfail.png',
    }
  }
  return (
    <>
      <VerificationStatusSection status={emailStates[emailState]} />
    </>
  );
}

export default EmailVerificationPage;
