import React from "react";
import "./styles.scss";
import GetAppSection from "../../components/GetAppSection";
import { copyToClipboard } from "../../util/copyToClipboard";

var isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
};

function GetAppPage(props) {
  const goToAppStore = () => {
    const withReferrer = `https://testflight.apple.com/join/kvwx0JPW?referrer=${props.match.params.referral}`;
    const noReferrer = "https://testflight.apple.com/join/kvwx0JPW";
    window.location = props.match.params.referral && props.match.params.referral.length > 0 ? withReferrer : noReferrer;
  }
  const goToPlayStore = () => {
    const withReferrer = `intent://register/${props.match.params.referral}/#Intent;scheme=go-vest;package=com.govest;end`;
    const noReferrer = "https://play.google.com/apps/testing/com.govest";
    window.location = props.match.params.referral && props.match.params.referral.length > 0 ? withReferrer : noReferrer;
  }
  const goToStore = () => {
    if (isMobile.iOS()) {
      goToAppStore();
    } else {
      goToPlayStore();
    }
  }

  const openIos = () => {
    const withReferrer = `go-vest://register/${props.match.params.referral}`;
    const noReferrer = `go-vest://register`;
    window.location = props.match.params.referral && props.match.params.referral.length > 0 ? withReferrer : noReferrer;
  }
  const openAndroid = () => {
    const withReferrer = `intent://register/${props.match.params.referral}/#Intent;scheme=go-vest;package=com.govest;end`;
    const noReferrer = `intent://register/#Intent;scheme=go-vest;package=com.govest;end`;
    window.location = props.match.params.referral && props.match.params.referral.length > 0 ? withReferrer : noReferrer;

  }
  const openApp = () => {
    if (isMobile.iOS()) {
      openIos();
    } else {
      openAndroid();
    }
  }

  const onClick = () => {
    if (props.match.params.referral && props.match.params.referral.length > 0) {
      copyToClipboard(`go-vest:${props.match.params.referral}`);
    }
    setTimeout(() => {
      goToStore();
    }, 100)
    openApp();
  }
  return (
    <>
      <GetAppSection
        onButtonClick={onClick}
        buttonText={'Get Govest App'}
        text={"You're invited to Govest"}
        referral={"Referral Code"}
        code={props.match.params.referral}
        logo="/assets/govest_logo/icon dark bg copy.png"
        logoWebp="/assets/govest_logo/icon dark bg copy.web"
      />
    </>
  );
}

export default GetAppPage;
