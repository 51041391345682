import React, { useState } from 'react';
import './styles.scss';
import Logo from '../Logo';
import NavbarContainer from './../NavbarContainer';
import Language from '../../util/strings';
import { Link } from './../../util/router.js';
import { useAuth } from './../../util/auth.js';

function Navbar(props) {
  const auth = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  const setLanguage = lang => {
    Language.set(lang);
  };

  return (
    <NavbarContainer spaced={props.spaced} color={props.color} fixed={true}>
      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-item">
            <Logo
              style={{ maxHeight: '46px', padding: '5px' }}
              className="image logo-image"
            />
          </div>
          <div
            className={'navbar-burger burger' + (menuOpen ? ' is-active' : '')}
            onClick={() => setMenuOpen(!menuOpen)}>
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={'navbar-menu' + (menuOpen ? ' is-active' : '')}>
          <div className="navbar-end">
            {auth.user && (
              <div className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-link" to="/">
                  Account
                </Link>
                <div className="navbar-dropdown is-boxed">
                  <Link className="navbar-item" to="/dashboard">
                    Dashboard
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/signout"
                    onClick={e => {
                      e.preventDefault();
                      auth.signout();
                    }}>
                    Sign out
                  </Link>
                </div>
              </div>
            )}

            {!auth.user && (
              <>
                <a
                  className="navbar-item"
                  href="/#features"
                  onClick={() => setMenuOpen(false)}>
                  <strong>Features</strong>
                </a>
                {/* <a
                  className="navbar-item"
                  href="/faq"
                  onClick={() => setMenuOpen(false)}>
                  <strong>FAQ</strong>
                </a> */}
                <a
                  className="navbar-item"
                  href="/blog"
                  onClick={() => setMenuOpen(false)}>
                  <strong>Blog</strong>
                </a>
                <div className="navbar-item">
                  <a
                    className="button is-primary is-outlined"
                    href="#earlyAccess"
                    onClick={() => setMenuOpen(false)}>
                    Get Early Access
                  </a>
                </div>
                <div className="navbar-item navbar-language-container">
                  <div
                    className={
                      'navbar-language ' +
                      (Language.get() === 'en' ? 'navbar-language-active' : '')
                    }
                    onClick={() => setLanguage('en')}>
                    EN
                  </div>
                  <div
                    className={
                      'navbar-language ' +
                      (Language.get() === 'id' ? 'navbar-language-active' : '')
                    }
                    onClick={() => setLanguage('id')}>
                    ID
                  </div>
                </div>
                {/* 
                <Link className="navbar-item" to="/">
                  <strong>About</strong>
                </Link>
            */}
              </>
            )}
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
}

export default Navbar;
