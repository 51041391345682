import axios from "axios";

export const sendToSheets = (email, phone) => {
  var url = `https://script.google.com/macros/s/AKfycbwd_ZqBKRPmNFjfIca85C4eylNMoZmo2zf6sS1M-idPjnPX2ss/exec?email=${email}&phone=${phone}`;
  axios
    .get(url)
    .then(function(response) {
      console.log(response);
    })
    .catch(function(error) {
      console.log(error);
    });
};
