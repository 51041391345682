import React, { useState } from "react";
import FormStatus from "./../FormStatus";
import FormField from "./../FormField";
import SectionButton from "./../SectionButton";
import "./styles.scss";
import axios from "axios";

function ForgotPass(props) {
  const [status, setStatus] = useState();
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState(undefined);

  const onSubmit = ({ pass, confirmPass }) => {
    setError(undefined)
    if(pass !== confirmPass){
      setError("Passwords don't match")
      return
    }
    setStatus({ type: "pending" });
    
    axios.patch(props.resetURL, {
      new: pass,
    })
    .then(() => setStatus("success"))
    .catch(err => {
      setStatus("failed")
      setError("Failed submitting new password")
    })
  };

  return (
    <div className="Auth">
      {error ? <FormStatus type="error" message={error} /> : null}
      {status === "success" ? <FormStatus type="success" message="Successfully changed your password!" /> : null }

      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit({pass, confirmPass, error});
        }}
      >
        <FormField
          value={pass}
          type="password"
          placeholder="Password"
          // error={showErrors && getError("pass")}
          onChange={value => setPass(value)}
        />
        <FormField
          value={confirmPass}
          type="password"
          placeholder="Confirm Password"
          // error={showErrors && getError("confirmPass")}
          onChange={value => setConfirmPass(value)}
        />
        <div className="field">
          <p className="control">
            <SectionButton
              parentColor={props.parentColor}
              size="medium"
              fullWidth={true}
              state={
                props.status && props.status.type === "pending"
                  ? "loading"
                  : "normal"
              }
            >
              {props.buttonText}
            </SectionButton>
          </p>
        </div>
      </form>
    </div>
  );
}

export default ForgotPass;
