import React from 'react';
import './styles.scss';
import SectionHeader from '../SectionHeader';
import Perks from '../Perks';
import Section from '../Section';
import { getString, Strings } from '../../util/strings';

function PerksSection(props) {
  return (
    <div className="PerksSection__margin-top">
      <Section color={props.color} size={props.size} bgColor="gray">
        <div className="container">
          <div className="columns is-vcentered has-text-centered-mobile">
            <div className="column is-4 is-offset-1">
              <SectionHeader
                title={getString(Strings.Perks.startBuilding)}
                titleFont=" akkurat-bold-font is-size-5-mobile line-height-big"
                subtitle={getString(Strings.Perks.fastAndEasy)}
                subtitleFont=" akkurat-font line-height-big"
                size={4}
              />
            </div>
            <div className="column is-5 is-offset-1">
              <Perks
                items={[
                  {
                    title: getString(Strings.Perks.lowLatency),
                    image: 'assets/landing_assets/fast.png',
                    imageWebp: 'assets/landing_assets/fast.web',
                  },
                  {
                    title: getString(Strings.Perks.discussOnline),
                    image: 'assets/landing_assets/nomin.png',
                    imageWebp: 'assets/landing_assets/nomin.web',
                  },
                  {
                    title: getString(Strings.Perks.stocksAndETF),
                    image: 'assets/landing_assets/bar.png',
                    imageWebp: 'assets/landing_assets/bar.web',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}

export default PerksSection;
