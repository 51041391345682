import React from "react";
import "./styles.scss";
import Section from "../Section";

function BannerSection(props) {
    return (
        <div className="BannerSection">
            <Section>
                <div className="container">
                    <div className="columns is-variable is-8 is-vcentered has-text-centered-mobile">
                        <div className="column is-two-fifths is-vcentered">
                            <h3 className="BannerSection__title title poppins-font has-text-weight-bold is-spaced is-2">
                                {props.title}
                            </h3>
                            <h3 className="BannerSection__subtitle subtitle akkurat-bold-font has-text-weight-bold is-spaced is-5">
                                {props.subtitle}
                            </h3>
                            <p
                                className="BannerSection__description"
                            >{props.description}</p>
                        </div>
                        <div className="column is-vcentered is-centered">
                            <picture>
                                <source
                                    className="BannerSection__image"
                                    srcset={process.env.PUBLIC_URL + props.imageWebp}
                                    type="image/webp" />
                                <img
                                    className="BannerSection__image"
                                    src={process.env.PUBLIC_URL + props.image}
                                    alt={props.title}
                                />
                            </picture>
                        </div>
                    </div>
                </div>
            </Section>
        </div>
    );
}

export default BannerSection;
