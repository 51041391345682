import React, { useState, useEffect } from "react";
import BlogPost from "./../../components/BlogPost";
import axios from "axios";
import "./styles.scss";
import EarlyAccessBanner from "../../components/EarlyAccessBanner";
import BlogListSection from "../../components/BlogListSection";
import BannerSection from "../../components/BannerSection";
import { getString, Strings } from "../../util/strings";
import { sendToSheets } from "../../util/submitWaitlistSheets";

function BlogPage(props) {
  const [list, setList] = useState(null);

  const addEmailHandler = (email, phone) => {
    axios
      .post("https://www.go-vest.com/api/v0/waitlist/add", {
        email: email,
        utmSource: props.source
      })
      .then(function(response) {
        sendToSheets(email, phone);
      })
      .catch(function(error) {});
  };

  const getAllPosts = tags => {
    axios
      .get(`https://www.go-vest.com/api/v0/blog/list`)
      .then(function(response) {
        console.log(response);
        setList(response.data);
      })
      .catch(function(error) {});
  };

  useEffect(getAllPosts, []);
  return (
    <>
      <BannerSection
        title={getString(Strings.Blog.knowledgeCenter)}
        subtitle={getString(Strings.Blog.financeAndInvesting)}
        description={getString(Strings.Blog.newsInfoReads)}
        image={"/assets/landing_assets/coins.png"}
        imageWebp={"/assets/landing_assets/coins.web"}
      />
      <BlogListSection
        title={getString(Strings.Blog.financeStories)}
        subtitle={getString(Strings.Blog.easyReads)}
        gray
        posts={[]} // (related posts)
        list={list} // (all posts)
        center
      />
      <div id={"earlyAccess"}>
        <EarlyAccessBanner
          form
          title={getString(Strings.EarlyAccessBanner)}
          buttonOnClick={addEmailHandler}
          buttonText={getString(Strings.Home.joinWaitlist)}
        />
      </div>
    </>
  );
}

export default BlogPage;
