import React, { useState, useEffect } from "react";
import axios from 'axios';
import ForgotPassSection from "./../../components/ForgotPassSection";
import "./styles.scss";

function ForgotpassPage(props) {
  const [verified, setVerifiedState] = useState("loading");
  let { email, code } = props.match.params;
  
  const verify = () => {
    setVerifiedState("loading");
    axios.get(`https://www.go-vest.com/api/v0/user/forgot/${email}/${code}`)
    .then(() => setVerifiedState('success'))
    .catch(() => {
      setVerifiedState('failed')
    })
  }

  useEffect(() => {
    verify();
  }, []);
  
  return (
    <ForgotPassSection
      color="white"
      size="large"
      title="Reset Password"
      subtitle=""
      buttonText="Reset Password"
      verified={verified}
      resetURL={`https://www.go-vest.com/api/v0/user/reset/password/${email}/${code}`}
      {...props}
    />
  );
}

export default ForgotpassPage;
