import React from "react";
import Section from "../Section";
import "./styles.scss";
import { FormatDate } from "../../util/dateFormat";

function BlogListSection(props) {
  var posts = props.posts ? props.posts.filter((item) => item.ID !== props.id) : null;
  posts = posts ? posts.slice(0, props.limit) : [];
  if (posts.length <= 0) {
    posts = props.list ? props.list.filter((item) => item.ID !== props.id) : null;
    posts = posts ? posts.slice(0, props.limit) : [];
  }
  if (posts.length <= 0) {
    return null;
  }
  return (
    <Section className={`BlogListSection ${props.gray ? 'is-gray' : null} is-large`} >
      <div className={`BlogListSection__title akkurat-bold-font ${props.center ? 'has-text-centered' : null}`}>{props.title}</div>
      <div className={`BlogListSection__subtitle akkurat-font ${props.center ? 'has-text-centered' : null}`}>{props.subtitle}</div>
      <div className="BlogListSection__columns columns is-multiline">
        {posts.map((item) =>
          <div className="column is-4">
            <a href={`/blog/${item.ID}`}>
              <div className="BlogListSection__item">
                <div className="title akkurat-bold-font">{item.title}</div>
                <div className="date akkurat-font">{FormatDate(new Date(item.publishedAt))}</div>
                <div className="subtitle akkurat-font">{item.subtitle}</div>
              </div>
            </a>
          </div>
        )
        }
      </div>
    </Section>
  );
}

export default BlogListSection;
