import React from "react";
import Section from "./../Section";
import { Link } from "./../../util/router.js";
import "./styles.scss";
import Logo from "../Logo";
import LicenseSection from "../LicenseSection";
import { getString, Strings } from "../../util/strings";

function Footer(props) {
  const fbLogo = "/assets/landing_assets/facebook_logo.png";
  const igLogo = "/assets/landing_assets/instagram_logo.png";
  return (
    <Section color={props.color} size={props.size}>
      <div className="FooterComponent container">
        <div className="FooterComponent__container columns">
          <div className="license column right">
            <div className="FooterComponent__title akkurat-bold-font">
              {getString(Strings.Footer.registeredWith)}
            </div>
            <LicenseSection
              items={[
                {
                  image: "/assets/landing_assets/logo-kominfo.png",
                  imageWebp: "/assets/landing_assets/logo-kominfo.web",
                  title: "Kominfo PSE",
                  details: "01987/DJAI.PSE/11/2019"
                }
              ]}
            />
          </div>

          <div className="links column is-3">
            <div className="FooterComponent__title akkurat-bold-font">
              Legal
            </div>
            <a
              target="_blank"
              href="https://storage.googleapis.com/govest/Terms%20and%20Conditions.pdf"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>
            <a
              target="_blank"
              href="https://storage.googleapis.com/govest/Privacy%20Policy.pdf"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
          <div className="column is-2">
            <div className="FooterComponent__title akkurat-bold-font">
              {getString(Strings.Footer.followUs)}
            </div>
            <div className="social">
              <a target="_new" href="https://www.facebook.com/govestapp/">
                <img
                  width="30"
                  height="30"
                  src={process.env.PUBLIC_URL + fbLogo}
                  alt="Govest on Facebook"
                />
              </a>
              <a target="_new" href="https://www.instagram.com/govestapp/">
                <img
                  width="30"
                  height="30"
                  src={process.env.PUBLIC_URL + igLogo}
                  alt="Govest on Instagram"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="FooterComponent__brand container">
        <Logo />
        <div className="copyright">{props.copywrite}</div>
      </div>
      <div className="FooterComponent__description container">
        <div className="akkurat-bold-font">Disclaimer</div>
        {getString(Strings.Footer.disclaimerText).map(item => (
          <div className=" akkurat-font">{item}</div>
        ))}
      </div>
    </Section>
  );
}

export default Footer;
