import React from 'react';
import './styles.scss';

function Features(props) {
  return (
    <div className="Features Features__margin-top">
      {props.items.map((item, index) => (
        <div
          className="Features__margin-top Features__columns columns is-variable is-8 is-vcentered has-text-centered-mobile"
          key={index}>
          <div className="column is-half">
            <picture>
              <source
                className="Features__no-classname image"
                srcset={process.env.PUBLIC_URL + item.imageWebp}
                type="image/webp"
              />
              <img
                className="Features__no-classname image"
                src={process.env.PUBLIC_URL + item.image}
                alt={item.title}
              />
            </picture>
          </div>
          <div className="column">
            <h6 className="Features__grey-color title is-6 is-uppercase char-spacing has-text-weight-bold">
              {item.header}
            </h6>
            <h3 className="Features__title title poppins-font has-text-weight-bold is-spaced is-2">
              {item.title}
            </h3>
            <p className="subtitle Features__grey-color Features__400-text-weight">
              {item.description}
            </p>
            {item.linkURL ? (
              <a href={item.linkURL} target="new">
                {item.linkText}
              </a>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Features;
