import React from 'react';
import { Link } from './../../util/router.js';

function Logo(props) {
  var logo = '/assets/govest_logo/text_with_logo.png';
  var logoWebp = '/assets/govest_logo/text_with_logo.web';

  return (
    <a href="/">
      <picture>
        <source srcset={process.env.PUBLIC_URL + logoWebp} type="image/webp" />
        <img
          style={props.style}
          className={props.className}
          src={process.env.PUBLIC_URL + logo}
          alt="Logo"
        />
      </picture>
    </a>
  );
}

export default Logo;
