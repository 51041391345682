import React from "react";
import "./styles.scss";

function VerificationStatusSection(props) {
  return (
    <div className="VerificationStatusSection__container columns is-centered is-multiline">
      <div className="column is-4 has-text-centered is-centered">
        <div className="column is-half is-offset-one-quarter">
          <img
            src={process.env.PUBLIC_URL + props.status.image}
            alt={props.status.title}
          />
        </div>
        <div className="VerificationStatusSection__title title is-5 akkurat-bold-font">{props.status.title}</div>
        <div className="VerificationStatusSection__subtitle subtitle is-6 akkurat-font">{props.status.subtitle}</div>
      </div>
    </div>
  );
}

export default VerificationStatusSection;
