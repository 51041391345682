import React from "react";
import "./styles.scss";
import SectionHeader from "../SectionHeader";
import Section from "../Section";
import EmailForm from "../EmailForm";

function EarlyAccessBanner(props) {
  return (
    <div className="EarlyAccessBanner columns is-vcentered is-centered has-text-centered">
      <Section size={props.size}>
        <div className="column">
          <SectionHeader
            title={props.title}
            titleFont=" akkurat-bold-font is-size-5-mobile blackText"
            size={4}
          />
        </div>
        {props.form ? (
          <EmailForm
            buttonText={props.buttonText}
            subscribedMessage="You are on the list! We'll let you know when your invite for access is ready."
            subscriptionError={false}
            size="medium"
            addEmailHandler={props.buttonOnClick}
            buttonColor="dark"
            center
          />
        ) : (
          <div className="column">
            <button
              className="EarlyAccessBanner__button akkurat-bold-font"
              onClick={props.buttonOnClick}
            >
              {props.buttonText}
            </button>
          </div>
        )}
      </Section>
    </div>
  );
}

export default EarlyAccessBanner;
