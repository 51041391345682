import React from "react";
import "./styles.scss";

function SectionSubHeader(props) {
  return (
    <>
      <div className={"title has-text-weight-semi-bold" + 
        (props.titleFont || "") +
        (props.size ? ` is-${props.size}` : "") +
        (props.spaced ? " is-spaced" : "") +
        (props.centered ? " has-text-centered" : "")}
      >
        {props.title}</div>
      <div className={"subtitle has-text-grey-light" + 
        (props.subtitleFont || "") +
        (props.size > 4 ? " is-6" : "") +
        (props.centered ? " has-text-centered" : "")}
      >
        {props.subtitle}</div>
    </>
  )
}

export default SectionSubHeader;