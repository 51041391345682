import React from "react";
import "./styles.scss";

function LicenseSection(props) {
    return (
        <div className={`LicenseSection ${props.mobile ? "LicenseSection__mobile" : "LicenseSection__desktop"}`}>
            <div className="LicenseSection__columns columns is-mobile">
                {props.items.map((item, index) => (
                    <div className="LicenseSection__column column is-centered is-narrow has-text-centered">
                        <picture>
                            <source
                                className="LicenseSection__image"
                                srcset={process.env.PUBLIC_URL + item.imageWebp}
                                type="image/webp" />
                            <img
                                className="LicenseSection__image"
                                src={process.env.PUBLIC_URL + item.image}
                                alt={item.title}
                            />
                        </picture>
                        <div className="LicenseSection__details akkurat-font subtitle">{item.details}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LicenseSection;


