import React from "react";
import "./styles.scss";
import { copyToClipboard } from "../../util/copyToClipboard";

var isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
};

function SharePage(props) {
  const openIos = () => {
    window.location = `go-vest://register/${props.match.params.referral}`;
  }
  const openAndroid = () => {
    window.location = `intent://register/${props.match.params.referral}/#Intent;scheme=go-vest;package=com.govest;end`;
  }
  const openApp = () => {
    if (props.match.params.referral && props.match.params.referral.length > 0) {
      copyToClipboard(`go-vest:${props.match.params.referral}`);
    }
    if (isMobile.iOS()) {
      openIos();
    } else {
      openAndroid();
    }
  }

  setTimeout(() => {
    window.location = `${process.env.PUBLIC_URL}/getapp/${props.match.params.referral}`;
  }, 100)
  openApp();
  return null
}

export default SharePage;