import React from "react";
import Navbar from "./../../components/Navbar";
import HomePage from "./../home";
import AboutPage from "./../about";
import FaqPage from "./../faq";
import SharePage from "./../share";
import GetAppPage from "./../getapp";
import PricingPage from "./../pricing";
import ContactPage from "./../contact";
import DashboardPage from "./../dashboard";
import SigninPage from "./../signin";
import SignupPage from "./../signup";
import ForgotpassPage from "./../forgotpass";
import ChangepassPage from "./../changepass";
import EmailVerificationPage from "./../emailverify";
import { Switch, Route, Router } from "./../../util/router.js";
import Footer from "./../../components/Footer";
import { ProvideAuth } from "./../../util/auth.js";
import "./styles.scss";
import BlogPostPage from "../blogpost";
import BlogPage from "../blog";

function App(props) {
  return (
    <ProvideAuth>
      <Router>
        <>
          <Navbar
            color="white"
            spaced={true}
          />

          <Switch>
            <Route exact path="/" component={HomePage} />

            <Route exact path="/emailverification/:email/:token" component={EmailVerificationPage} />

            <Route exact path="/forgot/:email/:code" component={ForgotpassPage} />

            {/* <Route exact path="/about" component={AboutPage} /> */}

            {/* <Route exact path="/faq" component={FaqPage} /> */}

            <Route exact path="/share/:referral" component={SharePage} />
            <Route exact path="/getapp/:referral?" component={GetAppPage} />
            <Route exact path="/blog" component={BlogPage} />
            <Route exact path="/blog/:id" component={BlogPostPage} />

            {/* <Route exact path="/pricing" component={PricingPage} /> */}

            {/* <Route exact path="/contact" component={ContactPage} /> */}

            {/* <Route exact path="/dashboard" component={DashboardPage} /> */}

            {/* <Route exact path="/signin" component={SigninPage} /> */}

            {/* <Route exact path="/signup" component={SignupPage} /> */}

            {/* <Route exact path="/changepass" component={ChangepassPage} /> */}

            <Route
              component={({ location }) => {
                return (
                  <div
                    style={{
                      padding: "50px",
                      width: "100%",
                      textAlign: "center"
                    }}
                  >
                    The page <code>{location.pathname}</code> could not be
                    found.
                  </div>
                );
              }}
            />
          </Switch>

          <Footer
            color="dark"
            size="normal"
            copywrite="© PT Govest Teknologi Indonesia 2020"
          />
        </>
      </Router>
    </ProvideAuth>
  );
}

export default App;
