import React, { useState, useEffect } from 'react';
import Section from './../Section';
import axios from 'axios';
import marked from 'marked';
import './styles.scss';
import ShareIcons from '../ShareIcons';
import { FormatDate } from '../../util/dateFormat';
import BlogListSection from '../BlogListSection';
import { getString, Strings } from '../../util/strings';

function BlogPost(props) {
  const [mdText, setMdText] = useState(null);
  const [blog, setBlog] = useState(null);
  const [author, setAuthor] = useState(null);
  const [related, setRelated] = useState(null);
  const [list, setList] = useState(null);

  const getPost = async () => {
    getAllPosts();
    axios
      .get(`https://www.go-vest.com/api/v0/blog/get/${props.id}`)
      .then(function(response) {
        console.log(response);
        setMdText(response.data.text);
        setBlog(response.data.blog);
        setAuthor(response.data.author);
        getRelatedPost(response.data.blog.tags);
      })
      .catch(function(error) {
        setBlog({ title: 'Not Found' });
        setAuthor(null);
      });
  };

  const getRelatedPost = tags => {
    axios
      .get(`https://www.go-vest.com/api/v0/blog/list/tag/${tags}`)
      .then(function(response) {
        console.log(response);
        setRelated(response.data);
      })
      .catch(function(error) {});
  };

  const getAllPosts = tags => {
    axios
      .get(`https://www.go-vest.com/api/v0/blog/list`)
      .then(function(response) {
        console.log(response);
        setList(response.data);
      })
      .catch(function(error) {});
  };

  useEffect(getPost, []);

  var publishedTime =
    blog && blog.publishedAt ? FormatDate(new Date(blog.publishedAt)) : null;
  var title = blog ? blog.title : null;
  var subtitle = blog ? blog.subtitle : null;
  return (
    <>
      <Section>
        <div className="BlogPost columns">
          <div className="column"></div>
          <div className="BlogPost__column column is-10">
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
            {blog && blog.url ? (
              <div className="publisher_container columns is-mobile">
                <div className="publisher column">
                  <img
                    className="image"
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/landing_assets/author.png'
                    }
                    alt={'Author Profile'}
                  />
                  <div className="author">
                    <div className="name akkurat-font">{author}</div>
                    <div className="date akkurat-font">{publishedTime}</div>
                  </div>
                </div>
                <div className="share column is-narrow">
                  <ShareIcons small url={window.location.href} title={title} />
                </div>
              </div>
            ) : null}

            {blog && blog.url ? (
              <div
                className="BlogPost__md content"
                dangerouslySetInnerHTML={{ __html: marked(mdText) }}
              />
            ) : null}

            {blog && blog.url ? (
              <div className="BlogPost__share">
                <ShareIcons
                  full
                  share
                  url={window.location.href}
                  title={title}
                />
              </div>
            ) : null}
            <div className="BlogPost__disclosure akkurat-font">
              <i>
                {getString(Strings.Blog.investingAppFooter)}
                <a href="#earlyAccess">
                  {getString(Strings.Blog.getEarlyAccessFooter)}
                </a>
                .
              </i>
              <br />
              <br />
              <i className="small">
                Govest does not provide investment advice and individual
                investors should make their own decisions or seek independent
                advice. The value of investments can go up as well as down and
                you may receive back less than your original investment. Tax
                laws are subject to change and may vary in how they apply
                depending on the circumstances.
              </i>
            </div>
          </div>
          <div className="column"></div>
        </div>
      </Section>
      <BlogListSection
        title={'Related Posts'}
        list={list}
        posts={related}
        id={blog ? blog.ID : 0}
        limit={3}
        gray
      />
    </>
  );
}

export default BlogPost;
