import React, { useState } from "react";
import BlogPost from "./../../components/BlogPost";
import axios from "axios";
import "./styles.scss";
import EarlyAccessBanner from "../../components/EarlyAccessBanner";
import { sendToSheets } from "../../util/submitWaitlistSheets";

function BlogPostPage(props) {
  const addEmailHandler = (email, phone) => {
    axios
      .post("https://www.go-vest.com/api/v0/waitlist/add", {
        email: email,
        utmSource: props.source
      })
      .then(function(response) {
        sendToSheets(email, phone);
      })
      .catch(function(error) {});
  };
  return (
    <>
      <BlogPost id={props.match.params.id} />
      <div id={"earlyAccess"}>
        <EarlyAccessBanner
          form
          title={"Get early access to US Stocks from Indonesia"}
          buttonOnClick={addEmailHandler}
          buttonText={"Join Waitlist"}
        />
      </div>
    </>
  );
}

export default BlogPostPage;
