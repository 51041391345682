import React, { useState, useEffect } from "react";
import axios from "axios";
import Section from "../Section";
import SectionHeader from "../SectionHeader";
import SectionSubHeader from "../SectionSubHeader";
import "./styles.scss";
import EmailForm from "../EmailForm";
import { getString, Strings } from "../../util/strings";
import { sendToSheets } from "../../util/submitWaitlistSheets";

function HeroSection(props) {
  const [numEmails, setNumEmails] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(false);

  const { color, size, title, image, imageExt } = props;

  const resetEmailCount = () => {
    axios
      .get("https://www.go-vest.com/api/v0/waitlist/count")
      .then(function(response) {
        setNumEmails(response.data);
      })
      .catch(function(error) {});
  };

  // Email Counter
  useEffect(resetEmailCount);

  const addEmailHandler = (email, phone) => {
    axios
      .post("https://www.go-vest.com/api/v0/waitlist/add", {
        email: email,
        utmSource: props.source
      })
      .then(function(response) {
        // Handle Success - if successful, refresh waitlist count
        resetEmailCount();
        sendToSheets(email, phone);
      })
      .catch(function(error) {
        // Handle Error - Duplicate User
        // console.log('Error in adding user to the waitlist.', error); // Currently a Status Code 500 Error
        setSubscriptionError(true);
      });
  };

  return (
    <Section color={color} size={size}>
      <div className="container">
        <div className="columns is-vcentered is-desktop">
          <div className="column is-5-desktop HeroSection__margin-top">
            <div className="column is-10-desktop" style={{ padding: 0 }}>
              <SectionHeader
                title={title}
                titleFont=" poppins-font is-size-3-mobile"
                size={2}
              />
            </div>
            <SectionSubHeader
              title={getString(Strings.Home.getEarlyAccess)}
              titleFont=" akkurat-font normal-weight"
              size={4}
            />
            <SectionSubHeader
              title={getString(Strings.Home.join150Others)}
              titleFont=" akkurat-font padded"
              size={6}
            />
            <EmailForm
              parentColor={color}
              buttonText={getString(Strings.Home.joinWaitlist)}
              subscribedMessage={getString(Strings.Home.youreOnTheList)}
              subscriptionError={subscriptionError}
              size="medium"
              addEmailHandler={addEmailHandler}
            />
          </div>
          {/* <div className="column is-1" /> */}
          <div className="column">
            <picture>
              <source
                className="HeroSection__image image"
                srcset={`
                  ${process.env.PUBLIC_URL}${image}@4x.web 1500w,
                  ${process.env.PUBLIC_URL}${image}@3x.web 1000w,
                  ${process.env.PUBLIC_URL}${image}@2x.web 500w,
                  ${process.env.PUBLIC_URL}${image}@1x.web 250w
            `}
                type="image/webp"
              />
              <img
                className="HeroSection__image image"
                srcset={`
                  ${process.env.PUBLIC_URL}${image}@4x${imageExt} 1500w,
                  ${process.env.PUBLIC_URL}${image}@3x${imageExt} 1000w,
                  ${process.env.PUBLIC_URL}${image}@2x${imageExt} 500w,
                  ${process.env.PUBLIC_URL}${image}@1x${imageExt} 250w
                `}
                src={`${process.env.PUBLIC_URL}${image}@3x${imageExt}`}
                alt="Illustration"
              />
            </picture>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection;
