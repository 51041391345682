import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import ForgotPass from "./../ForgotPass";
import "./styles.scss";

function ForgotPassSection(props) {
  const Loading = () => (
    <div>
      Loading..
    </div>
  );

  const Form = () => (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          textCentered={true}
          size={3}
        />
        <ForgotPass 
          buttonText={props.buttonText} 
          parentColor={props.color} 
          resetURL={props.resetURL}
        />
      </div>
    </Section>
  );

  const Failed = () => (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title="Oops, this link has expired"
          subtitle="Please request a new link to reset your password"
          centered={true}
          size={3}
        />
      </div>
    </Section>
  )

  switch(props.verified) {
    case 'loading':
      return Loading();
    case 'success':
      return Form();
    case 'failed':
      return Failed();
    default:
      return null
  }
}

export default ForgotPassSection;
