import React from "react";
import "./styles.scss";
import SectionButton from "../SectionButton";
import Logo from "../Logo";

function GetAppSection(props) {
  return (
    <div className="GetAppSection__container columns is-centered is-multiline">
      <div className="column is-4 has-text-centered is-centered">
        <div className="column is-half is-offset-one-quarter">
          <picture>
            <source srcset={process.env.PUBLIC_URL + props.logoWebp} type="image/webp" />
            <img style={props.style} className={props.className} src={process.env.PUBLIC_URL + props.logo} alt="Logo" />
          </picture>
          {props.code && props.code.length > 0 ?
            <>
              <div className="akkurat-font">{props.text}</div>
              <div className="akkurat-bold-font referral-text">{props.referral}</div>
              <div className="referral-code">{props.code}</div>
            </>
            : null
          }
        </div>

        <div className="column is-half is-offset-one-quarter">
          <SectionButton
            bgColor=" govest-bg-color"
            size={"medium"}
            fullWidth
            onClick={props.onButtonClick}
          >
            {props.buttonText}
          </SectionButton>
        </div>
      </div>
    </div>
  );
}

export default GetAppSection;
