export default class Language {
  static set(lang) {
    localStorage.setItem("lang", lang);
    window.location.reload();
  }
  static get() {
    return localStorage.getItem("lang") ? localStorage.getItem("lang") : "id";
  }
}

export const Strings = {
  Home: {
    investFromIndo: {
      en: "Cutting-edge investing tools and data. For Everyone.",
      id: "Alat dan data investasi saham US untuk semua orang",
    },
    secureSpot: {
      en: "Secure your spot, get early access.",
      id: "Ikuti waitlist dan dapatkan akses lebih awal.",
    },
    joinWaitlist: {
      en: "Join Waitlist",
      id: "Daftar Waitlist",
    },
    getEarlyAccess: {
      en: "Get Early Access",
      id: "Dapatkan Akses Lebih Awal",
    },
    join150Others: {
      en: "Join 150+ million others who invest in US Stocks.",
      id: "Bergabunglah dengan 150+ juta investor Saham US.",
    },
    youreOnTheList: {
      en:
        "You are on the list! We'll let you know when your invite for access is ready.",
      id:
        "Kamu sudah terdaftar di waitlist! Kami akan menghubungi kamu ketika aplikasi kami siap diakses.",
    },
    enterValidEmail: {
      en: "Please enter a valid email address.",
      id: "Email tidak valid.",
    },
    enterValidPhone: {
      en: "Please enter a valid phone number.",
      id: "Nomor telpon tidak valid.",
    },
  },
  Features: {
    thousandStocks: {
      en: "Thousands of Stocks",
      id: "Ribuan data saham",
    },
    brandsYouLove: {
      en: "Access data of the brands you love.",
      id: "Akses data saham brand yang kamu kenal.",
    },
    industryLeadingCompanies: {
      en:
        "Apple, Starbucks, Google - Start tracking performance of the brands you're familiar with. Build your watchlist with industry-leading companies in technology, health, renewable energy, and more.",
      id:
        "Apple, Starbucks, Google - Ikuti perkembangan perusahaan-perusahaan yang kamu kenali. Bangun watchlist kamu dengan perushaan industri terkemuka di bidang teknologi, kesehatan, energi, dan banyak lagi.",
    },
    insuredBySIPC: {
      en: "Insured by SIPC",
      id: "Asuransi oleh SIPC",
    },
    safeAndSecure: {
      en: "Safe & Secure",
      id: "Aman & Terpercaya",
    },
    protectedBySIPC: {
      en:
        "Your account is protected by SIPC against the loss of securities and cash in your brokerage account up to $500,000.",
      id:
        "Akun kamu dilindungi oleh SIPC terhadap hilangnya sekuritas dan uang tunai di akun broker kamu hingga $500.000.",
    },
    learnMore: {
      en: "Learn more",
      id: "Pelajari lebih lanjut",
    },
  },
  Perks: {
    startBuilding: {
      en: "Start building your US Portfolio",
      id: "Mulailah membangun Portofolio US kamu",
    },
    fastAndEasy: {
      en: "Simplified US stock data analytics and discussion.",
      id: "Analisa data ribuan saham US dan ajak teman anda untuk berdiskusi.",
    },
    lowLatency: {
      en: "Access data with low latency",
      id: "Akses data dengan latensi tercepat",
    },
    discussOnline: {
      en: "Discuss with thousands of other investors",
      id: "Diskusi dengan ribuan investor lain",
    },
    stocksAndETF: {
      en: "Thousands of Stocks & ETFs Data",
      id: "Data Ribuan saham dan ETF",
    },
  },
  FAQ: {
    frequentlyAskedQuestions: {
      en: "Frequently Asked Questions",
      id: "Frequently Asked Questions",
    },
    howTaxWork: {
      en: "How do taxes work?",
      id: "Bagaimana perpajakan investasi saham US?",
    },
    howTaxWorkAns: {
      usAndIndo: {
        en: "The United States of America and Indonesia have a",
        id: "Amerika Serikat dan Indonesia memiliki persetujuan",
      },
      doubleTaxTreaty: {
        en: " Double Taxation Treaty",
        id: " Double Taxation Treaty",
      },
      investorNotTaxed: {
        en:
          " which means that you as an investor will not be taxed in both countries. Therefore, you will only need to abide by the Indonesian taxation rules. In this case, the profit or loss that you get from your investments will be classified as income tax which follows the PPh 21 tax rule as mentioned ",
        id:
          " yang berarti bahwa kamu sebagai investor tidak akan dikenakan pajak di kedua negara. Karena itu, kamu hanya perlu mematuhi aturan perpajakan Indonesia. Dalam hal ini, keuntungan atau kerugian yang kamu dapatkan dari investasi akan diklasifikasikan sebagai pajak penghasilan yang mengikuti aturan pajak PPh 21 sebagaimana disebutkan ",
      },
      here: {
        en: "here.",
        id: "disini.",
      },
    },
    requirementsRegister: {
      en: "What are the requirements to register?",
      id: "Apa saja yang harus saya siapkan untuk registrasi?",
    },
    requirementsRegisterAns: {
      en:
        "For KYC (Know-Your-Customer) purposes, Govest users are required to upload and submit their KTP (Kartu Tanda Penduduk) and a selfie via either the iOS or Android Govest application.",
      id:
        "Untuk tujuan KYC (Know-Your-Customer), pengguna Govest diharuskan untuk mengunggah foto KTP (Kartu Tanda Penduduk) mereka dan selfie melalui aplikasi iOS atau Android Govest.",
    },
    moneySafe: {
      en: "Is my money safe?",
      id: "Apakah uang saya aman?",
    },
    moneySafeAns: {
      en:
        "Govest’s broker partner in the US is a licensed broker-dealer registered with the SEC, and a member of SIPC (Securities Investor Protection Corporation). SIPC protects against the loss of cash and securities held by a customer up to $500,000, which includes a $250,000 limit for cash. SIPC does not protect you for any decline in the value of your securities. For the details, please visit ",
      id:
        "Partner broker Govest di US adalah broker-dealer berlisensi yang terdaftar di SEC, dan merupakan anggota SIPC (Securities Investor Protection Corporation). SIPC melindungi terhadap kehilangan uang tunai dan sekuritas yang dimiliki oleh pelanggan hingga $500.000 dengan batas uang tunai sebesar $250.000. SIPC tidak melindungi kamu dari penurunan nilai sekuritas. Untuk detailnya, silakan kunjungi ",
    },
  },
  Blog: {
    knowledgeCenter: {
      en: "Knowledge Center",
      id: "Knowledge Center",
    },
    financeAndInvesting: {
      en: "Personal finance and investing",
      id: "Personal finance dan investasi",
    },
    newsInfoReads: {
      en: "Financial news, information, and daily reads",
      id: "Berita finansial, informasi, dan artikel ringan",
    },
    financeStories: {
      en: "Finance Stories",
      id: "Artikel Finansial",
    },
    easyReads: {
      en: "Easy reads about money, investing, and reaching financial goals.",
      id:
        "Artikel ringan tentang uang, investasi, dan mencapai tujuan financial.",
    },
    investingAppFooter: {
      en: `Govest is an app that allows Indonesians to research and 
      analyze US Stocks data. Join 150+ million people who follows US Stocks movements
      through our waitlist and `,
      id: `Govest adalah aplikasi analisis saham US online yang dibuat untuk orang Indonesia. 
      Bergabunglah dengan 150+ juta orang yang mengikuti pergerakan Saham US
      melalui waitlist dan `,
    },
    getEarlyAccessFooter: {
      en: "get early access",
      id: "dapatkan akses lebih awal",
    },
    noInvestmentAdviceFooter: {
      en: `Govest does not provide investment advice and individual
      investors should make their own decisions or seek independent
      advice. The value of investments can go up as well as down. Tax
      laws are subject to change and may vary in how they apply
      depending on the circumstances.`,
      id: `Govest tidak memberikan saran investasi dan setiap
      investor harus membuat keputusan sendiri atau mencari nasihat independen. 
      Nilai investasi bisa naik maupun turun. Undang-undang perpajakan dapat berubah 
      dan penerapannya dapat bervariasi tergantung keadaan.`,
    },
  },
  EarlyAccessBanner: {
    en: "Get early access to US Stocks data from Indonesia",
    id: "Dapatkan akses awal data tercepat dan terbaik saham US di Indonesia",
  },
  Footer: {
    registeredWith: {
      en: "Registered with",
      id: "Terdaftar dengan",
    },
    followUs: {
      en: "Follow us on",
      id: "Ikuti kami",
    },
    disclaimerText: {
      en: [
        `All information placed on this website is not intended as investment
          advice. Further, any information on the website is for informational
          purposes only and does not constitute as an offer, solicitation of an
          offer, or advice to buy or sell securities. Any content and service
          provided by Govest shall not be considered a solicitation to any person in any
          jurisdiction where such solicitation would be illegal.`,

        `Please note, investing involves risk and investments may lose value. 
          Past performance does not guarantee future results.`,

        `Govest provides no tax, legal, or investment advice of any kind, 
          nor does Govest give advice or offer opinions with respect to the 
          nature, potential value, or suitability of any securities transaction or investment strategy.`,
      ],
      id: [
        `Semua informasi yang ditempatkan di situs web ini tidak dimaksudkan sebagai nasihat investasi. 
      Lebih lanjut, setiap informasi di situs web ini hanya ditujukan sebagai informasi
      dan bukan merupakan penawaran atau saran untuk membeli atau menjual sekuritas. Konten dan layanan apa pun
      yang disediakan oleh Govest tidak dianggap sebagai ajakan kepada siapapun di jurisdiksi manapun di 
      mana solisitasi tersebut dianggap ilegal.`,

        `Harap diperhatikan, investasi melibatkan risiko dan investasi dapat kehilangan nilainya. 
      Kinerja masa lalu tidak menjamin hasil di masa mendatang.`,

        `Govest tidak memberikan saran pajak, hukum, atau 
      investasi dalam bentuk apa pun, Govest tidak memberikan 
      saran atau menawarkan pendapat sehubungan dengan sifat, nilai potensial, 
      atau kesesuaian setiap transaksi efek atau strategi investasi.`,
      ],
    },
  },
};

export const getString = (text) => {
  return text[Language.get()];
};
