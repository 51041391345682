import React from 'react';
import Section from './../Section';
// import SectionHeader from "./../SectionHeader";
import Features from './../Features';
import './styles.scss';
import { getString, Strings } from '../../util/strings';

function FeaturesSection(props) {
  return (
    <>
      {/* <Section color={props.color} size={props.size} bgColor=" has-background-primary">
        <div className="container">
          <Features
            items={[
              {
                header: "Personalized Portfolio Allocation",
                title: "Get a tailored recommendation just for you.",
                description:
                  "Based on your risk level and balance, we’ll give you a list of stocks to buy, which one to buy first, and remind you when you have more funds.",
                evenColor: " has-text-white",
                image: "https://static.wixstatic.com/media/6f45bf_565119d90ba7424e8ef4435b4d24bc93~mv2_d_1205_1382_s_2.png/v1/fill/w_1205,h_1354,al_c,q_85/recommended.webp"
              }
            ]}
          />
        </div>
      </Section> */}
      <Section
        color={props.color}
        size={props.size}
        bgColor=" has-background-white">
        <div className="container">
          <Features
            items={[
              {
                header: getString(Strings.Features.thousandStocks),
                title: getString(Strings.Features.brandsYouLove),
                description: getString(
                  Strings.Features.industryLeadingCompanies,
                ),
                image: 'assets/landing_assets/stocks.png',
                imageWebp: 'assets/landing_assets/stocks.web',
              },
            ]}
          />
        </div>
      </Section>
    </>
  );
}

export default FeaturesSection;
