import React from "react";
import "./styles.scss";
import SectionHeader from "../SectionHeader";

function Perks(props) {
  return (
    <div className="Perks">
      {props.items.map((item, index) => (
        <div className="columns is-mobile is-vcentered">
          <div className="Perks__column column is-narrow">
            <picture>
              <source
                className="Perks__icon image"
                srcset={process.env.PUBLIC_URL + item.imageWebp}
                type="image/webp" />
              <img
                className="Perks__icon image"
                src={process.env.PUBLIC_URL + item.image}
                alt={item.title}
              />
            </picture>
          </div>
          <div className="Perks__column column is-narrow">
            <SectionHeader
              title={item.title}
              titleFont=" akkurat-font vertical-center has-text-left is-size-6-mobile"
              size={5}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Perks;
