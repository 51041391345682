import React, { useRef } from 'react';
import HeroSection from '../../components/HeroSection';
import ClientsSection from './../../components/ClientsSection';
import FeaturesSection from './../../components/FeaturesSection';
import FinancialsSection from './../../components/FinancialsSection';
import FaqSection from './../../components/FaqSection';
import { useRouter } from './../../util/router.js';
import './styles.scss';
import EarlyAccessBanner from '../../components/EarlyAccessBanner';
import PerksSection from '../../components/PerksSection';
import { getString, Strings } from '../../util/strings';

function HomePage(props) {
  let search = props.location.search;
  let params = new URLSearchParams(search);
  let source = params.get('utm_source');

  const router = useRouter();

  const myRef = useRef(null);
  const executeScroll = () => {
    // `current` points to the mounted text input element
    window.scrollTo(0, myRef.current.offsetTop);
  };

  return (
    <>
      <div ref={myRef} id="earlyAccess">
        <HeroSection
          color="white"
          size="small"
          title={getString(Strings.Home.investFromIndo)}
          image="assets/landing_assets/phoneBlob3D"
          imageExt=".png"
          buttonOnClick={() => {
            router.push('/pricing');
          }}
          source={source}
        />
      </div>
      {/* <FinancialsSection
        color="white"
        size="medium"
        title="Have you thought about your financial independence?"
        subtitle="Choose the right path onto financial independence."
      /> */}
      <div id="features">
        <FeaturesSection color="white" size="medium" />
      </div>
      <PerksSection color={props.color} />
      <EarlyAccessBanner
        title={getString(Strings.Home.secureSpot)}
        buttonText={getString(Strings.Home.joinWaitlist)}
        buttonOnClick={executeScroll}
      />
    </>
  );
}

export default HomePage;
