import React, { useState } from "react";
import * as EmailValidator from "email-validator";
import "./styles.scss";
import { validatePhone } from "../../util/phoneValidator";
import { getString, Strings } from "../../util/strings";

function EmailForm(props) {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const invalidEmailMessage = getString(Strings.Home.enterValidEmail);
  const invalidPhoneMessage = getString(Strings.Home.enterValidPhone);

  const handleSubmit = () => {
    setInvalidPhone(false);
    setInvalidEmail(false);
    if (email && phone) {
      if (!validatePhone(phone)) {
        setInvalidPhone(true);
        return;
      }

      if (!EmailValidator.validate(email)) {
        setInvalidEmail(true);
        return;
      }
      // TODO: Add an API call here. For example:
      //API.newsletterSubscribe({ email });
      props.addEmailHandler(email, phone);

      setSubscribed(true);
      // Parent component can optionally
      // find out when subscribed.
      props.onSubscribed && props.onSubscribed();
    }
  };

  const showMessage = (subscribed, subscribedMessage, subscriptionError) => {
    if (subscribed && !subscriptionError) {
      return <div className=" akkurat-font">{subscribedMessage}</div>;
    } else if (subscriptionError) {
      return (
        <div className=" akkurat-font">You are already on the waitlist!</div>
      );
    }
    return <></>;
  };

  return (
    <>
      {subscribed === false && (
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div
            className={`input-container + ${
              props.center ? "input-container-center" : null
            }`}
          >
            <div className="input-row">
              <div className="input-label akkurat-bold-font">Email</div>
              <input
                className="input-text akkurat-font"
                type="email"
                placeholder="name@email.com"
                onChange={event => setEmail(event.target.value)}
              />
            </div>
            <div className="input-row">
              <div className="input-label akkurat-bold-font">Phone</div>
              <input
                className="input-text akkurat-font"
                type="phone"
                placeholder="+628123456789"
                onChange={event => setPhone(event.target.value)}
              />
            </div>
          </div>
          <button
            className="form-button akkurat-bold-font"
            onClick={props.buttonOnClick}
            style={{
              backgroundColor:
                props.buttonColor === "dark" ? "#33ce9f" : "#22eeb1"
            }}
          >
            {props.buttonText}
          </button>
          {invalidEmail ? (
            <div className="has-text-danger">{invalidEmailMessage}</div>
          ) : (
            ""
          )}
          {invalidPhone ? (
            <div className="has-text-danger">{invalidPhoneMessage}</div>
          ) : (
            ""
          )}
        </form>
      )}
      {showMessage(
        subscribed,
        props.subscribedMessage,
        props.subscriptionError
      )}
    </>
  );
}

export default EmailForm;
